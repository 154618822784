import { Component, OnInit, AfterViewChecked, LOCALE_ID, HostListener } from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { MatDialog } from '@angular/material/dialog';

import { ProductModel } from '../../models/product.model';
import { ResourcesService } from "../../services/resources/resources.service";
import { ProductsService } from "../../services/products/products.service";
import { SettingsService } from "../../services/settings/settings.service";
import { AdobeTargetService } from '../../services/adobe-target/adobe-target.service';

import { ToClipboardModalComponent } from '../../modals/to-clipboard-modal/to-clipboard-modal.component';
import { RenewModalComponent } from '../../modals/renew-modal/renew-modal.component';

declare global {
  interface Window {
    Trustpilot: any;
  }
}


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, AfterViewChecked {

  assetsHref = environment.assetsHref;
  product: ProductModel;
  maintenanceId: string;
  resources: any;
  features: any;
  numberOfDevices: any;
  durations: any;
  subscriptionYear: any;
  loading: boolean;
  pricesLoading: boolean;
  productWPrices: any;
  productCode: string;
  maintenanceLicenses: string;
  culture: string;
  configuration: any;
  countryIsoCode: string;
  coupon: string;
  xtrack: string;
  subscriptionMonths: number;
  productPrice: string;
  promoPrice: string;
  discount: string;
  idSubClass: number;
  idVersionType: number;
  mI: string;
  selectedDevices: string;
  selectedDuration: number;
  scenarios1: boolean;
  scenarios1Code: string;
  scenarios1Devices: string;
  scenarios1Duration: number;
  scenarios1Price: string;
  scenarios1PromoPrice: string;
  scenarios1Discount: string;
  scenarios1Image: string;
  scenarios1Functionalities: any;
  scenarios1MissingFeatures = [];
  scenarios2: boolean;
  scenarios2Code: string;
  scenarios2Devices: string;
  scenarios2Duration: number;
  scenarios2Price: string;
  scenarios2PromoPrice: string;
  scenarios2Discount: string;
  scenarios2Image: string;
  scenarios2Functionalities: any;
  scenarios2MissingFeatures = [];
  scenarios3: boolean;
  scenarios3Code: string;
  scenarios3Devices: string;
  scenarios3Duration: number;
  scenarios3Price: string;
  scenarios3PromoPrice: string;
  scenarios3Discount: string;
  scenarios3Image: string;
  scenarios3Functionalities: any;
  scenarios3MissingFeatures = [];
  scenarios4: boolean;
  scenarios4Code: string;
  scenarios4Devices: string;
  scenarios4Duration: number;
  scenarios4Price: string;
  scenarios4PromoPrice: string;
  scenarios4Discount: string;
  scenarios4Image: string;
  scenarios4Functionalities: any;
  scenarios4MissingFeatures = [];
  featuresLoading: boolean;
  recommended: string;
  hideRecommended: boolean;
  recommendedIndex: number = 1;
  maintenanceIdNumber: string;
  products: any;
  url: string;
  scenarios1Obj: any;
  scenarios2Obj: any;
  scenarios3Obj: any;
  scenarios4Obj: any;
  deviceType: string;
  selectedDeviceNum: number;
  recommendedProduct: any;
  selectDevice: number;
  recommendedOnPage: any;
  idSubClassFound: boolean;
  isLegacyProduct: boolean;
  isUniqueScenario: boolean;
  recommendedProductDetail: any;
  innerWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(public productsService: ProductsService,
    public settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    public resourcesService: ResourcesService,
    private adobeTargetService: AdobeTargetService,
    private router: Router) {

    this.maintenanceId = this.activatedRoute.snapshot.params.maintenanceId;

  }

  ngAfterViewChecked() {
    const trustboxRef = document.getElementById('trustbox');
    const trustboxRef2 = document.getElementById('trustbox3');
    window.Trustpilot.loadFromElement(trustboxRef);
    window.Trustpilot.loadFromElement(trustboxRef2);
  }

  ngOnInit() {

    this.innerWidth = window.innerWidth;

    this.url = this.router['location']._platformLocation.location.origin;

    if (this.activatedRoute.snapshot.url[1]) {
      this.url = this.url + '/product/' + this.activatedRoute.snapshot.url[1].path;

    }

    function isMobile() {
      var check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
          check = true;
      })(navigator.userAgent);
      return check;
    };

    let deviceType = '';
    if (isMobile() == true) {
      this.deviceType = 'Mobile'
    } else {
      this.deviceType = 'Desktop'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    const resources = localStorage.getItem('resources');
    this.resources = JSON.parse(resources);

    this.recommended = localStorage.getItem('recommended');

    this.maintenanceIdNumber = localStorage.getItem('maintenanceId');

    this.culture = localStorage.getItem('culture');

    this.settingsService.getSettings().subscribe(data => {
      if (!this.culture) {
        this.culture = data.Localization.Culture;
        localStorage.setItem('culture', data.Localization.Culture);
      }
      
      this.countryIsoCode = data.Localization.CountryIsoCode;
      localStorage.setItem('countryIsoCode', this.countryIsoCode);

      this.resourcesService.getResources(this.culture).subscribe(data => {

        this.resources = data;
        localStorage.setItem('resources', JSON.stringify(data));
        this.numberOfDevices = [1, 3, 5, 10, this.resources.Unlimited];

        this.productsService.getProduct(this.maintenanceId).subscribe(data => {
          this.product = data;
          let product = this.product;
          let productRec = this.recommendedProduct;

          this.product = data;

          this.productCode = data.Code;
          this.selectDevice = data.MaintenanceLicenses;
          this.selectedDevices = data.MaintenanceLicenses + 'PC';
          this.selectedDeviceNum = +(data.MaintenanceLicenses);
          if (data.MaintenanceLicenses == -1) { this.selectedDeviceNum = 20 }

          if (data.MaintenanceLicenses == -1) {
            this.selectedDevices = 'MULTI';
          }

          this.idSubClass = data.SubClassId;

          this.idVersionType = data.VersionType;

          this.mI = this.culture;


          this.maintenanceLicenses = data.MaintenanceLicenses + 'PC';
          if (data.MaintenanceLicenses == -1) {
            this.maintenanceLicenses = 'MULTI';
          }

          this.subscriptionMonths = data.SubscriptionMonths;

          if (data.SubscriptionMonths == 0) {

            this.subscriptionMonths = 12
          }

          let d = new Date();
          d.getHours();
          d.getMinutes();
          d.getSeconds();

          let dayOfWeek = d.getDay();
          let weekOrWeekend = ''
          let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
          if (isWeekend == true) {
            weekOrWeekend = 'weekend'
          } else {
            weekOrWeekend = 'week'
          }

          let productStatus = '';
          if (data.ProductStatus.Status == 1) {
            productStatus = 'active';
          } else {
            productStatus = 'expired';
          }

          let pipe = new DatePipe('en-US');
          const subscriptionEndDate = pipe.transform(data.ExpirationDate, 'dd/MM/yyyy');

          this.adobeTargetService.setDataLayer({
            event: 'gtm.datalayer',
            eventType: 'pageview',
            eventCategory: '',
            eventAction: '',
            eventLabel: '',
            eventValue: '',
            pageName: 'renewal area:products and services.product',
            pageSection: 'renewal area',
            pageCategory: 'products and services',
            pageSubcategory1: 'product',
            pageSubcategory2: '',
            pageSubcategory3: '',
            pageSubcategory4: '',
            url: 'https://renewals.pandasecurity.com/product/' + this.maintenanceId,
            previousPage: 'renewal area:products and services',
            referer: 'https://renewals.pandasecurity.com/',
            originURL: localStorage.getItem('url'),
            accessType: this.deviceType,
            hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
            day: new Date().toLocaleString('en-us', { weekday: 'long' }),
            weekday: weekOrWeekend,
            language: this.culture.substr(0, 2),
            pageCode: '200-ok',
            pageAccess: 'private',
            clientId: localStorage.getItem('customerId'),
            clientIdList: this.product.CustomerId,
            userLanguage: this.culture.substr(0, 2),
            clientType: 'particular',
            clientCreatedDate: '',
            sessionLoginStatus: 'logged',
            productName: data.Name,
            productId: this.maintenanceId,
            activationCode: this.product.ActivationCode,
            productSKU: '',
            productState: productStatus,
            productDevices: data.MaintenanceLicenses,
            productMonths: data.SubscriptionMonths,
            productSubscriptionType: data.SubscriptionType,
            productSubscriptionStartDate: '',
            productSubscriptionEndDate: subscriptionEndDate,
            productsCode: localStorage.getItem('products'),
            productsVersionType: localStorage.getItem('productsVersion'),

          }, 'products and services.product');

          this.productsService.getStoreProduct(this.productCode, this.maintenanceLicenses, this.subscriptionMonths, this.countryIsoCode, this.coupon).subscribe(data => {

            this.productWPrices = data;

            this.productPrice = this.productWPrices.PriceLine;

            this.promoPrice = this.productWPrices.PromoLine;

            this.discount = this.productWPrices.Discount;

          },
            error => {
              console.log(error);
              this.loading = false;
            });

          this.productsService.getProductScenarios(this.idSubClass, 2, this.mI).subscribe(data => {

            if (data[1]) {
              this.selectedDevices = +(data[1].ColDevices[0].devices_code) + 'PC';
              this.selectedDeviceNum = +(data[1].ColDevices[0].devices_code);

              if (data[1].ColDevices[0].devices_code == 'IL') { this.selectedDeviceNum = 20 }

              if (data[1].ColDevices[0].devices_code == 'IL') {
                this.selectedDevices = 'MULTI';
              }

            } else if(data[0]) {
              this.selectedDevices = +(data[0].ColDevices[0].devices_code) + 'PC';
              this.selectedDeviceNum = +(data[0].ColDevices[0].devices_code);

              if (data[0].ColDevices[0].devices_code == 'IL') { this.selectedDeviceNum = 20 }

              if (data[0].ColDevices[0].devices_code == 'IL') {
                this.selectedDevices = 'MULTI';
              }
            }

            if (data[1]) {

              if ((this.product.Code != data[1].code) && (this.product.Code != 'PD4A' && data[1].code != 'P4A')) {
                this.isLegacyProduct = true;
              }

            } else if ((this.product.Code != data[0].code) && (this.product.Code != 'PD4A' && data[0].code != 'P4A')) {
              this.isLegacyProduct = true;
            }
            

            if (this.recommended && (this.maintenanceId == this.maintenanceIdNumber)) {
              for (let x = 0; x < data.length; x++) {

                for (let y = 0; y < data[x].ColDevices.length; y++) {

                  if (data[x].ColDevices[y].idsubclase == this.recommended) {
                    this.recommendedIndex = x;

                  }

                }
              }
              if (!this.recommendedIndex) {
                this.recommendedIndex = 1;
              }

            }
            if (data[1]) {

              let selectDevice = this.selectDevice + '';
              if (this.selectDevice == -1) {
                selectDevice = 'IL'
              } else if (this.selectDevice == 2) {
                selectDevice = data[1].ColDevices[0].devices_code;

              }

              for (let y = 0; y < data[1].ColDevices.length; y++) {
                if (data[1].ColDevices[y].devices_code == String(selectDevice).padStart(2, '0')) {
                  this.recommendedProduct = data[1].ColDevices[y].idsubclase;
                }
              }
            } else if (data[0]) {
              let selectDevice = this.selectDevice + '';
              if (this.selectDevice == -1) {
                selectDevice = 'IL'
              } else if (this.selectDevice == 2) {
                selectDevice = data[1].ColDevices[0].devices_code;
              }
              for (let y = 0; y < data[0].ColDevices.length; y++) {
                if (data[0].ColDevices[y].devices_code == String(selectDevice).padStart(2, '0')) {
                  this.recommendedProduct = data[0].ColDevices[y].idsubclase;
                }
              }
            }
            if (this.recommended) {
              for (let x = 0; x < data.length; x++) {

                for (let y = 0; y < data[x].ColDevices.length; y++) {

                  if (data[x].ColDevices[y].idsubclase == this.recommended) {

                    this.idSubClassFound = true;
                  }
                }
              }
            }
            if (this.recommended && this.idSubClassFound != true) {
              this.recommended = this.recommendedProduct;
            }
            let redirectToModal = localStorage.getItem('openModal');
            if (redirectToModal) {
              localStorage.removeItem('openModal');
              let dialogRef = this.matDialog.open(RenewModalComponent, {
                minHeight: '500px',
                minWidth: '520px',
                data: {
                  product,
                  recommended: this.recommendedProduct
                }
              });
            }

            let maintenance = localStorage.getItem('maintenance');
            if (maintenance) {
              localStorage.removeItem('maintenance');
              let dialogRef = this.matDialog.open(RenewModalComponent, {
                minHeight: '500px',
                minWidth: '520px',
                data: {
                  product,
                  recommended: this.recommended || this.recommendedProduct
                }
              });
            }
            if (!data[1]) {
              this.isUniqueScenario = true;
            }
            if (data[0]) {
              if (data.length == 1 && (data[0].code == this.productCode) || (data[0].code == 'P4A' && this.productCode == 'PD4A')) {
                this.hideRecommended = true;
              }

              this.scenarios1 = true;
              this.scenarios1Obj = data[0];

              this.scenarios1Code = data[0].code;

              let selectedDevices = +(data[0].ColDevices[0].devices_code) + 'PC';
              if (data[0].ColDevices[0].devices_code == 'IL') {
                selectedDevices = 'MULTI'
              } else {
                selectedDevices = (+(data[0].ColDevices[0].devices_code)).toString() + 'PC'
              }

              this.scenarios1Devices = selectedDevices;

              this.scenarios1Duration = this.subscriptionMonths; 
              

              this.productsService.getStoreProduct(this.scenarios1Code, this.scenarios1Devices, this.scenarios1Duration, this.countryIsoCode, this.coupon).subscribe(data => {

                if (this.isLegacyProduct && this.isUniqueScenario == true) {
                  this.productPrice = data.PriceLine;
                  this.promoPrice = data.PromoLine;
                  this.discount = data.Discount;
                }

                this.scenarios1Price = data.PriceLine;
                this.scenarios1Image = data.Image;
                this.scenarios1PromoPrice = data.PromoLine;
                this.scenarios1Discount = data.Discount;
                this.scenarios1Functionalities = data.Functionalities;
                let featuresLength = this.scenarios1Functionalities.length;
                this.scenarios1MissingFeatures = new Array(10 - featuresLength);
                this.loading = false;
              },
                error => {
                  console.log(error);
                  this.loading = false;
                });
            }
            if (data[1]) {
              this.scenarios2 = true;
              this.scenarios2Obj = data[1];
              this.scenarios2Code = data[1].code;

              let selectedDevices = +(data[1].ColDevices[0].devices_code) + 'PC';
              if (data[1].ColDevices[0].devices_code == 'IL') {
                selectedDevices = 'MULTI'
              } else {
                selectedDevices = (+(data[1].ColDevices[0].devices_code)).toString() + 'PC'
              }

              this.scenarios2Devices = selectedDevices;
              this.scenarios2Duration = this.subscriptionMonths;
              this.recommendedProduct = data[1];

              this.productsService.getStoreProduct(this.scenarios2Code, this.scenarios2Devices, this.scenarios1Duration, this.countryIsoCode, this.coupon).subscribe(data => {

                if (this.isLegacyProduct) {
                  this.productPrice = data.PriceLine;
                  this.promoPrice = data.PromoLine;
                  this.discount = data.Discount;
              }

                this.scenarios2Price = data.PriceLine;
                this.scenarios2Image = data.Image;
                this.scenarios2PromoPrice = data.PromoLine;
                this.scenarios2Discount = data.Discount;
                this.scenarios2Functionalities = data.Functionalities;
                let featuresLength = this.scenarios2Functionalities.length;
                this.scenarios2MissingFeatures = new Array(10 - featuresLength);

              },
                error => {
                  console.log(error);
                  this.loading = false;
                });
            }
            if (data[2]) {
              this.scenarios3 = true;
              this.scenarios3Obj = data[2];
              this.scenarios3Code = data[2].code;

              let selectedDevices = +(data[2].ColDevices[0].devices_code) + 'PC';
              if (data[2].ColDevices[0].devices_code == 'IL') {
                selectedDevices = 'MULTI'
              } else {
                selectedDevices = (+(data[2].ColDevices[0].devices_code)).toString() + 'PC'
              }

              this.scenarios3Devices = selectedDevices;
              this.scenarios3Duration = this.subscriptionMonths;

              this.productsService.getStoreProduct(this.scenarios3Code, this.scenarios3Devices, this.scenarios3Duration, this.countryIsoCode, this.coupon).subscribe(data => {

                this.scenarios3Price = data.PriceLine;
                this.scenarios3Image = data.Image;
                this.scenarios3PromoPrice = data.PromoLine;
                this.scenarios3Discount = data.Discount;
                this.scenarios3Functionalities = data.Functionalities;
                let featuresLength = this.scenarios3Functionalities.length;
                this.scenarios3MissingFeatures = new Array(10 - featuresLength);

              },
                error => {
                  console.log(error);
                  this.loading = false;
                });
            }
            if (data[3]) {
              this.scenarios4 = true;
              this.scenarios4Obj = data[3];
              this.scenarios4Code = data[3].code;

              let selectedDevices = +(data[3].ColDevices[0].devices_code) + 'PC';
              if (data[3].ColDevices[0].devices_code == 'IL') {
                selectedDevices = 'MULTI'
              } else {
                selectedDevices = (+(data[3].ColDevices[0].devices_code)).toString() + 'PC'
              }

              this.scenarios4Devices = selectedDevices;
              this.scenarios4Duration = this.subscriptionMonths;

              this.productsService.getStoreProduct(this.scenarios4Code, this.scenarios4Devices, this.scenarios4Duration, this.countryIsoCode, this.coupon).subscribe(data => {

                this.scenarios4Price = data.PriceLine;
                this.scenarios4Image = data.Image;
                this.scenarios4PromoPrice = data.PromoLine;
                this.scenarios4Discount = data.Discount;
                this.scenarios4Functionalities = data.Functionalities;
                let featuresLength = this.scenarios4Functionalities.length;
                this.scenarios4MissingFeatures = new Array(10 - featuresLength);

                setTimeout(() => {
                  this.loading = false;
                }, 3000);
              },
                error => {
                  console.log(error);
                  this.loading = false;
                });
            }
          },
            error => {
              console.log(error);
              this.loading = false;
            })
        },
          error => {
            console.log(error);
            this.loading = false;
          });
        error => {
          console.log(error);
        }
      });

      error => {
        console.log(error);
      }
    });


    this.coupon = localStorage.getItem('coupon');

    this.xtrack = localStorage.getItem('xtrack');
    if (resources) {
      this.numberOfDevices = [1, 3, 5, 10, this.resources.Unlimited];
    }
    this.durations = [12, 24, 36];

    this.loading = true;

    this.resourcesService.getFeatures(this.culture).subscribe(data => {
      this.features = data
    });
    this.productsService.getProducts().subscribe(data => {
      this.products = data;
    });

    
  
  }

  onClickCopyCode(copyCode) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'code',
      eventLabel: copyCode,
      eventValue: '',
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');

    copyCode = copyCode;

    let textElement = document.createElement('textarea');

    textElement.value = copyCode;

    textElement.setAttribute('readonly', '');

    document.body.appendChild(textElement);

    textElement.select();

    document.execCommand('copy');

    document.body.removeChild(textElement);

    let dialogRef = this.matDialog.open(ToClipboardModalComponent, {
      disableClose: true,
      minHeight: '6rem',
    });

    setTimeout(() => {

      dialogRef.close();

    }, 1500);

  }
  onChangeDevices(numOfDevices, duration) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let devices = numOfDevices;
    if (numOfDevices == 'MULTI') {
      devices = 25;
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'new devices',
      eventLabel: this.product.ActivationCode,
      eventValue: devices,
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');

    let productCode = '';
    if (this.isLegacyProduct == true) {
      if (this.scenarios2Code) {
        productCode = this.scenarios2Code;
      } else {
        productCode = this.scenarios1Code;
      }
    } else {
      productCode = this.productCode;
    }

    this.pricesLoading = true;
    this.productsService.getStoreProduct(productCode, numOfDevices.target.value, duration, this.countryIsoCode, this.coupon).subscribe(data => {

      this.productWPrices = data;

      this.productPrice = this.productWPrices.PriceLine;

      this.promoPrice = this.productWPrices.PromoLine;

      this.discount = this.productWPrices.Discount;

      this.pricesLoading = false;

    },
      error => {
        console.log(error);
        this.pricesLoading = false;
      });
  }

  onChangeDuration(duration, numOfDevices) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'new months',
      eventLabel: this.product.ActivationCode,
      eventValue: duration.target.value,
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');


    if (numOfDevices == -1) {
      numOfDevices = 'MULTI';
    }
    this.pricesLoading = true;
    let productCode = '';
    if (this.isLegacyProduct == true) {
      if (this.scenarios2Code) {
        productCode = this.scenarios2Code;
      } else {
        productCode = this.scenarios1Code;
      }
    } else {
      productCode = this.productCode;
    }

    this.productsService.getStoreProduct(productCode, numOfDevices, duration.target.value, this.countryIsoCode, this.coupon).subscribe(data => {

      this.productWPrices = data;

      this.productPrice = this.productWPrices.PriceLine;

      this.promoPrice = this.productWPrices.PromoLine;

      this.discount = this.productWPrices.Discount;

      this.pricesLoading = false;

    },
      error => {
        console.log(error);
        this.pricesLoading = false;
      });
  }
  onClickGoRenew(numOfDevices, duration) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'renew',
      eventLabel: this.product.ActivationCode,
      eventValue: '',
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');

    this.loading = true;
    let productCode = '';
    if (this.isLegacyProduct == true) {
      productCode = this.scenarios2Code;
    } else {
      productCode = this.productCode;
    }
    this.productsService.getRenewUrl(this.maintenanceId, productCode, numOfDevices, duration, this.coupon, this.xtrack).subscribe(data => {
      this.loading = false;
      sessionStorage.setItem('hiddenFields', JSON.stringify(data));
      this.router.navigate(['clever/bridge']);
    },
      error => {
        this.loading = false;
        console.log(error);

      });
  }

  onClickGoRenewScenario(scenariosObj, code, numOfDevices, duration) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let devicesCode = '';
    if (numOfDevices == '3PC') {
      devicesCode = '03';
    } else if (numOfDevices == '5PC') {
      devicesCode = '05'
    } else if (numOfDevices == '10PC') {
      devicesCode = '10'
    } else if (numOfDevices == 'MULTI') {
      devicesCode = '25'
    }

    let deviceIdSubclass = '';

    for (let y = 0; y < scenariosObj.ColDevices.length; y++) {

      if (scenariosObj.ColDevices[y].devices_code == devicesCode) {
        deviceIdSubclass = scenariosObj.ColDevices[y].idsubclase;
      }
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'improve my plan',
      eventLabel: this.product.ActivationCode,
      eventValue: deviceIdSubclass,
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');

    this.loading = true;
    this.productsService.getRenewUrl(this.maintenanceId, code, numOfDevices, duration, this.coupon, this.xtrack).subscribe(data => {
      this.loading = false;
      sessionStorage.setItem('hiddenFields', JSON.stringify(data));
      this.router.navigate(['clever/bridge']);
    },
      error => {
        this.loading = false;
        console.log(error);

      });
  }

  onChangeRecommendedDevices(devices, duration, recommendedNum, deviceOrDuration) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let devicesCode = '';
    if (devices == '1PC') {
      devicesCode = '01';
    } else if (devices == '3PC') {
      devicesCode = '03'
    } else if (devices == '5PC') {
      devicesCode = '05'
    } else if (devices == '10PC') {
      devicesCode = '10'
    } else if (devices == 'MULTI') {
      devicesCode = '25'
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    if (deviceOrDuration == 'devices') {
      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'product',
        eventAction: 'improve new devices',
        eventLabel: this.product.ActivationCode,
        eventValue: devicesCode,
        pageName: 'renewal area:products and services.product',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: 'product',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: 'renewal area:products and services',
        referer: 'https://renewals.pandasecurity.com/',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: this.product.CustomerId,
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: this.product.Name,
        productId: this.product.MaintenanceId,
        activationCode: this.product.ActivationCode,
        productSKU: '',
        productState: productStatus,
        productDevices: this.product.MaintenanceLicenses,
        productMonths: this.product.SubscriptionMonths,
        productSubscriptionType: this.product.SubscriptionType,
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: subscriptionEndDate,
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services.product.product');
    }
    if (deviceOrDuration == 'duration') {
      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'product',
        eventAction: 'improve new months',
        eventLabel: this.product.ActivationCode,
        eventValue: duration,
        pageName: 'renewal area:products and services.product',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: 'product',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: 'renewal area:products and services',
        referer: 'https://renewals.pandasecurity.com/',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: this.product.CustomerId,
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: this.product.Name,
        productId: this.product.MaintenanceId,
        activationCode: this.product.ActivationCode,
        productSKU: '',
        productState: productStatus,
        productDevices: this.product.MaintenanceLicenses,
        productMonths: this.product.SubscriptionMonths,
        productSubscriptionType: this.product.SubscriptionType,
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: subscriptionEndDate,
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services.product.product');
    }

    if (recommendedNum == '1') {
      this.featuresLoading = true;
      this.productsService.getStoreProduct(this.scenarios1Code, devices, duration, this.countryIsoCode, this.coupon).subscribe(data => {

        this.scenarios1Price = data.PriceLine;
        this.scenarios1PromoPrice = data.PromoLine;
        this.featuresLoading = false;
      },
        error => {
          console.log(error);
          this.featuresLoading = false;
        });
    }
    if (recommendedNum == '2') {
      this.featuresLoading = true;
      this.productsService.getStoreProduct(this.scenarios2Code, devices, duration, this.countryIsoCode, this.coupon).subscribe(data => {

        this.scenarios2Price = data.PriceLine;
        this.scenarios2PromoPrice = data.PromoLine;
        this.featuresLoading = false;

      },
        error => {
          console.log(error);
          this.featuresLoading = false;
        });
    }
    if (recommendedNum == '3') {
      this.featuresLoading = true;
      this.productsService.getStoreProduct(this.scenarios3Code, devices, duration, this.countryIsoCode, this.coupon).subscribe(data => {

        this.scenarios3Price = data.PriceLine;
        this.scenarios3PromoPrice = data.PromoLine;
        this.featuresLoading = false;
      },
        error => {
          console.log(error);
          this.featuresLoading = false;
        });
    }
    if (recommendedNum == '4') {
      this.featuresLoading = true;
      this.productsService.getStoreProduct(this.scenarios4Code, devices, duration, this.countryIsoCode, this.coupon).subscribe(data => {

        this.scenarios4Price = data.PriceLine;
        this.scenarios4PromoPrice = data.PromoLine;
        this.featuresLoading = false;
      },
        error => {
          console.log(error);
          this.featuresLoading = false;
        });
    }

  }

  onClickReturn() {
    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'navigation',
      eventLabel: 'back',
      eventValue: '',
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate, 
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');
  }

  onClickClickDevicesDatalayer() {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'devices',
      eventLabel: this.product.ActivationCode,
      eventValue: this.selectedDevices,
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');

  }

  onClickClickDurationDatalayer() {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'months',
      eventLabel: this.product.ActivationCode,
      eventValue: '',
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');

  }

  onClickClickScenariosDevicesDatalayer(scenariosObj, value2) {

    let devicesCode = '';
    if (value2 == '3PC') {
      devicesCode = '03';
    } else if (value2 == '5PC') {
      devicesCode = '05'
    } else if (value2 == '10PC') {
      devicesCode = '10'
    } else if (value2 == 'MULTI') {
      devicesCode = '25'
    }

    let deviceIdSubclass = '';

    for (let y = 0; y < scenariosObj.ColDevices.length; y++) {

      if (scenariosObj.ColDevices[y].devices_code == devicesCode) {
        deviceIdSubclass = scenariosObj.ColDevices[y].idsubclase;
      }
    }


    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'improve devices',
      eventLabel: this.product.ActivationCode,
      eventValue: deviceIdSubclass,
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');

  }

  onClickClickScenariosMonthsDatalayer(scenariosObj, value2, months) {

    let devicesCode = '';
    if (value2 == '3PC') {
      devicesCode = '03';
    } else if (value2 == '5PC') {
      devicesCode = '05'
    } else if (value2 == '10PC') {
      devicesCode = '10'
    } else if (value2 == 'MULTI') {
      devicesCode = '25'
    }

    let deviceIdSubclass = '';

    for (let y = 0; y < scenariosObj.ColDevices.length; y++) {

      if (scenariosObj.ColDevices[y].devices_code == devicesCode) {
        deviceIdSubclass = scenariosObj.ColDevices[y].idsubclase;
      }
    }


    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');


    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'product',
      eventAction: 'improve months',
      eventLabel: this.product.ActivationCode,
      eventValue: devicesCode,
      pageName: 'renewal area:products and services.product',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: '',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: 'renewal area:products and services',
      referer: 'https://renewals.pandasecurity.com/',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product');

  }

}
