import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';

import { environment } from '../../../environments/environment';

import { MatDialog } from '@angular/material/dialog';

import { UsersService } from "../../services/users/users.service";
import { SettingsService } from "../../services/settings/settings.service";
import { ResourcesService } from "../../services/resources/resources.service";
import { HelpPhoneService } from "../../services/help-phone/help-phone.service";
import { AdobeTargetService } from '../../services/adobe-target/adobe-target.service';

import { ProductsService } from "../../services/products/products.service";

import { ToClipboardModalComponent } from '../../modals/to-clipboard-modal/to-clipboard-modal.component';
import { RenewModalComponent } from '../../modals/renew-modal/renew-modal.component';

declare global {
  interface Window { Trustpilot: any; }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewChecked {

  assetsHref = environment.assetsHref;

  resourceAPIURL: string;

  culture: string;

  resources: any;

  configuration: any;

  products: any;

  loading: boolean;

  menuOpenedCard = false;

  itemMenuOpenedCard: number;

  helpPhone: string;

  deviceType: string;

  theresParams: boolean = false;

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    public userService: UsersService,
    public settingsService: SettingsService,
    public resourcesService: ResourcesService,
    public productsService: ProductsService,
    private helpPhoneService: HelpPhoneService,
    private adobeTargetService: AdobeTargetService) {
  }

  ngAfterViewChecked() {
    const trustboxRef = document.getElementById('trustbox');
    window.Trustpilot.loadFromElement(trustboxRef);
  }

  ngOnInit(): void {

    function isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return true
    }


    this.activatedRoute.queryParams.subscribe(params => {
      if (isEmpty(params) == false) {
        this.theresParams = true;
      }

      let model = {};
      let data = params['data'];
      let idcliente = params['idcliente'];
      let Nserie = params['Nserie'];
      let ID1 = params['ID1']
      let email = params['email']


      let coupon = params['coupon'] || localStorage.getItem('coupon');
      let xtrack = params['x-track'];
      let recommended = params['RecProduct'];

      for (let key in params) {
        if (params.hasOwnProperty(key) && key.startsWith('x-') || params.hasOwnProperty(key) && key.startsWith('y-')) {
          model[key] = params[key]
          
        }
      }

      let queryParams = '';
      let url = '';
      for (const [key, value] of Object.entries(params)) {
        queryParams = queryParams + key + "=" + value + "&";
      }
      if (queryParams != '') {
        url = this.router['location']._platformLocation.location.origin + queryParams;
        localStorage.setItem('url', url);
      } else if (queryParams = '' && !localStorage.getItem(url)) {
        url = this.router['location']._platformLocation.location.origin;
        localStorage.setItem('url', url);
      }

      let xyParamsObj = {};
      Object.keys(model).forEach(function (key) {
        var value = model[key];

        key = key.replace('y-', '')

        xyParamsObj[key] = value;
      });

      const xyParams = JSON.stringify(xyParamsObj);

      localStorage.setItem('xyParams', xyParams);


      if (!coupon) {
        coupon = '';

      }
      if (!xtrack) {
        xtrack = '';
      }
      if (!recommended) {
        recommended = '';
      }

      localStorage.setItem('xtrack', xtrack);
      localStorage.setItem('coupon', coupon);
      localStorage.setItem('recommended', recommended);

      if (data || (idcliente && Nserie) || (idcliente && ID1) || localStorage.getItem('token') || (idcliente && email)) {

        if (data) {
          

          const dataObject = { EncryptedData: data };

          this.userService.loginWithEncryptedData(dataObject).subscribe(data => {
            this.userService.setToken(data.Token);
            localStorage.setItem('customerEmail', data.Customer.Email);
            localStorage.setItem('maintenance', data.MaintenanceId);
            localStorage.setItem('maintenanceId', data.MaintenanceId);

            this.router.navigateByUrl('/product/' + data.MaintenanceId);
          },
            error => {
              sessionStorage.setItem('loginError', 'yes');
              this.router.navigateByUrl('/login');
              console.log(error);
            }
          );
        }

        if (idcliente && Nserie) {

          const data = { ClientId: idcliente, SerialNumber: Nserie };

          this.userService.loginWithSerialNumber(data).subscribe(data => {
            localStorage.setItem('customerEmail', data.Customer.Email);
            sessionStorage.setItem('idcliente', idcliente);
            this.userService.setToken(data.Token);

            localStorage.setItem('maintenance', data.MaintenanceId);
            localStorage.setItem('maintenanceId', data.MaintenanceId);

            this.router.navigateByUrl('/product/' + data.MaintenanceId);
          },
            error => {
              sessionStorage.setItem('loginError', 'yes');
              sessionStorage.setItem('idcliente', idcliente);
              this.router.navigateByUrl('/login');
              console.log(error);
            }
          );

        }
        if (idcliente && ID1) {
          console.log('idcliente', idcliente);
          const data = { ClientId: idcliente, SerialNumber: ID1 };

          this.userService.loginWithSerialNumber(data).subscribe(data => {
            localStorage.setItem('customerEmail', data.Customer.Email);
            sessionStorage.setItem('idcliente', idcliente);
            this.userService.setToken(data.Token);

            localStorage.setItem('maintenance', data.MaintenanceId);
            localStorage.setItem('maintenanceId', data.MaintenanceId);

            this.router.navigateByUrl('/product/' + data.MaintenanceId);
          },
            error => {
              sessionStorage.setItem('loginError', 'yes');
              sessionStorage.setItem('idcliente', idcliente);
              this.router.navigateByUrl('/login');
              console.log(error);
            }
          );

        }
        if (idcliente && email) {

          const data = { Email: email, clientId: idcliente };

          this.userService.login(data).subscribe(data => {

            localStorage.setItem('customerEmail', data.Customer.Email);
            sessionStorage.setItem('idcliente', idcliente);
            localStorage.setItem('customerId', data.Customer.CustomerId);

            this.userService.setToken(data.Token);

            this.router.navigateByUrl('/');

          }, error => {
            sessionStorage.setItem('loginError', 'yes');
            localStorage.setItem('customerEmail', email);
            sessionStorage.setItem('idcliente', idcliente);
            this.router.navigateByUrl('/login');
            console.log(error);
          });
        }
      } else {
        this.router.navigate(['/login']);
      }

    });

    function isMobile() {
      var check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
          check = true;
      })(navigator.userAgent);
      return check;
    };

    if (isMobile() == true) {
      this.deviceType = 'Mobile'
    } else {
      this.deviceType = 'Desktop'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    const configuration = localStorage.getItem('configuration');
    this.configuration = JSON.parse(configuration);

    this.culture = localStorage.getItem('culture');

    if ((!this.culture || !this.resources || !this.configuration) && !this.theresParams) {
      this.loading = true;
      this.settingsService.getSettings().subscribe(data => {

        localStorage.setItem('configuration', JSON.stringify(data));

        if (!this.culture) {
          localStorage.setItem('culture', data.Localization.Culture);
        }
        localStorage.setItem('countryIsoCode', data.Localization.CountryIsoCode);

        if (data.Localization.HelpPhones) {

          this.helpPhone = this.helpPhoneService.getHelpPhone(
            data.Localization.Culture, data.Localization.HelpPhones, data.Localization.Culture
          );

          localStorage.setItem('phone', this.helpPhone);

        }

        this.resourcesService.getResources(this.culture).subscribe(data => {
          this.loading = false;
          this.resources = data;

          localStorage.setItem('resources', JSON.stringify(data));


        },
          error => {
            console.log(error);
          });

      },
        error => {
          console.log(error);
        });
    }

    this.loading = true;
    if (!this.theresParams) {
      this.productsService.getProducts().subscribe(data => {

        this.loading = false;
        this.products = data;

        const clientIdArray = this.products.map(obj => obj.CustomerId);
        const saveClientIds = clientIdArray.join('|');
        sessionStorage.setItem('clientIdsList', saveClientIds);

        let d = new Date();
        d.getHours();
        d.getMinutes();
        d.getSeconds();

        let dayOfWeek = d.getDay();
        let weekOrWeekend = ''
        let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
        if (isWeekend == true) {
          weekOrWeekend = 'weekend'
        } else {
          weekOrWeekend = 'week'
        }

        let myArrCodes = [];
        let myArrVersionTypes = [];
        this.products.forEach(element => {
          myArrCodes.push(element.Code)
          myArrVersionTypes.push(element.VersionType)
        });

        localStorage.setItem('products', myArrCodes.join('|'));
        localStorage.setItem('productsVersion', myArrVersionTypes.join('|'));

        if (!this.loading) {
          this.adobeTargetService.setDataLayer({
            event: 'gtm.datalayer',
            eventType: 'pageview',
            eventCategory: '',
            eventAction: '',
            eventLabel: '',
            eventValue: '',
            pageName: 'renewal area:products and services',
            pageSection: 'renewal area',
            pageCategory: 'products and services',
            pageSubcategory1: '',
            pageSubcategory2: '',
            pageSubcategory3: '',
            pageSubcategory4: '',
            url: 'https://renewals.pandasecurity.com/',
            previousPage: 'renewal area:login',
            referer: 'https://renewals.pandasecurity.com/login',
            originURL: localStorage.getItem('url'),
            accessType: this.deviceType,
            hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
            day: new Date().toLocaleString('en-us', { weekday: 'long' }),
            weekday: weekOrWeekend,
            language: this.culture.substr(0, 2),
            pageCode: '200-ok',
            pageAccess: 'private',
            clientId: localStorage.getItem('customerId'),
            clientIdList: sessionStorage.getItem('clientIdsList'),
            userLanguage: this.culture.substr(0, 2),
            clientType: 'particular',
            clientCreatedDate: '',
            sessionLoginStatus: 'logged',
            productName: '',
            productId: '',
            productSKU: '',
            productState: '',
            productDevices: '',
            productMonths: '',
            productSubscriptionType: '',
            productSubscriptionStartDate: '',
            productSubscriptionEndDate: '',
            productsCode: myArrCodes.join('|'),
            productsVersionType: myArrVersionTypes.join('|'),

          }, 'products and services');
        }
      },
        error => {
          console.log(error);
          this.loading = false;
        });




    }
  }
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.sync.bootstrap.min.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  onClickCopyCode(copyCode) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'products and services',
      eventAction: 'code',
      eventLabel: copyCode,
      pageName: 'renewal area:products and services',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      url: 'https://renewals.pandasecurity.com/',
      previousPage: 'renewal area:login',
      referer: 'https://renewals.pandasecurity.com/login',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: sessionStorage.getItem('clientIdsList'),
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services');


        copyCode = copyCode;

        let textElement = document.createElement('textarea');

        textElement.value = copyCode;

        textElement.setAttribute('readonly', '');

        document.body.appendChild(textElement);

        textElement.select();

        document.execCommand('copy');

        document.body.removeChild(textElement);

        let dialogRef = this.matDialog.open(ToClipboardModalComponent, {
            disableClose: true,
            minHeight: '6rem',
        });

  }

  onClickRenewButton(product) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'products and services',
      eventAction: 'renew',
      eventLabel: product.ActivationCode,
      pageName: 'renewal area:products and services',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      url: 'https://renewals.pandasecurity.com/',
      previousPage: 'renewal area:login',
      referer: 'https://renewals.pandasecurity.com/login',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: sessionStorage.getItem('clientIdsList'),
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services');

    localStorage.setItem('openModal', 'yes');
    this.router.navigateByUrl('/product/' + product.MaintenanceId);
    //let dialogRef = this.matDialog.open(RenewModalComponent, {
    //  minHeight: '500px',
    //  minWidth: '520px',
    //  data: {
    //    product
    //  }
    //});
  }
  onClickMoreInformation(activationCode) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'products and services',
      eventAction: 'more information button',
      eventLabel: activationCode,
      pageName: 'renewal area:products and services',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      url: 'https://renewals.pandasecurity.com/',
      previousPage: 'renewal area:login',
      referer: 'https://renewals.pandasecurity.com/login',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: sessionStorage.getItem('clientIdsList'),
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services');

  }
  onMenuCard(item: number, state: string, activationCode) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'products and services',
      eventAction: 'card options',
      eventLabel: activationCode,
      pageName: 'renewal area:products and services',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      url: 'https://renewals.pandasecurity.com/',
      previousPage: 'renewal area:login',
      referer: 'https://renewals.pandasecurity.com/login',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: sessionStorage.getItem('clientIdsList'),
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services');

    this.itemMenuOpenedCard = item;

    this.menuOpenedCard = state === 'open';
  }

  onClickGoToProduct(activationCode) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'products and services',
      eventAction: 'more information options',
      eventLabel: activationCode,
      pageName: 'renewal area:products and services',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      url: 'https://renewals.pandasecurity.com/',
      previousPage: 'renewal area:login',
      referer: 'https://renewals.pandasecurity.com/login',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: sessionStorage.getItem('clientIdsList'),
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services');

  }

  onClickNavigateProduct(activationCode) {
    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'products and services',
      eventAction: 'more information arrow',
      eventLabel: activationCode,
      pageName: 'renewal area:products and services',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      url: 'https://renewals.pandasecurity.com/',
      previousPage: 'renewal area:login',
      referer: 'https://renewals.pandasecurity.com/login',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: localStorage.getItem('customerId'),
      clientIdList: sessionStorage.getItem('clientIdsList'),
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services');
  }


}
