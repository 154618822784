import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { SettingsService } from "../../services/settings/settings.service";
import { ResourcesService } from "../../services/resources/resources.service";
import { HelpPhoneService } from "../../services/help-phone/help-phone.service";
import { AdobeTargetService } from '../../services/adobe-target/adobe-target.service';

@Component({ 
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {
  isExpanded = false;

  responsiveBarsIcon = true;

  opacityUserIcon = false;

  assetsHref = environment.assetsHref;

  resourceAPIURL: string;

  culture: string;

  resources: any;

  configuration: any;

  helpPhone: string;

  customerEmail: string;

  url: string;

  deviceType: string;


  constructor(private router: Router,
    public settingsService: SettingsService,
    public resourcesService: ResourcesService,
    private helpPhoneService: HelpPhoneService,
    private adobeTargetService: AdobeTargetService,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {

    this.url = this.router['location']._platformLocation.location.origin;

    if (this.activatedRoute.snapshot.url[1]) {
      this.url = this.url + '/product/' + this.activatedRoute.snapshot.url[1].path;

    }

    function isMobile() {
      var check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
          check = true;
      })(navigator.userAgent);
      return check;
    };

    let deviceType = '';
    if (isMobile() == true) {
      this.deviceType = 'Mobile'
    } else {
      this.deviceType = 'Desktop'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    const resources = localStorage.getItem('resources');
    this.resources = JSON.parse(resources);

    this.culture = localStorage.getItem('culture');
    this.helpPhone = localStorage.getItem('phone');
    this.customerEmail = localStorage.getItem('customerEmail');

    const configuration = localStorage.getItem('configuration');
    this.configuration = JSON.parse(configuration);

    if (!configuration) {
      this.settingsService.getSettings().subscribe(data => {


        if (data.Localization.HelpPhones) {

          this.helpPhone = this.helpPhoneService.getHelpPhone(
            localStorage.getItem('countryIsoCode'), data.Localization.HelpPhones, data.Localization.Culture
          );

        }

        localStorage.setItem('configuration', JSON.stringify(data));

        error => {
          console.log(error);
        }

      });
    }
    if (configuration) {
      this.helpPhone = this.helpPhoneService.getHelpPhone(
        localStorage.getItem('countryIsoCode'), this.configuration.Localization.HelpPhones, this.configuration.Localization.Culture
      );
    }
  
    if (!this.culture || !this.resources) {
      this.settingsService.getSettings().subscribe(data => {

        this.culture = data.Localization.Culture;

        localStorage.setItem('culture', data.Localization.Culture);
        

        this.resourcesService.getResources(this.culture).subscribe(data => {

          this.resources = data;
          localStorage.setItem('resources', JSON.stringify(data));

          error => {
            console.log(error);
          }
        });

        error => {
          console.log(error);
        }
      });
    }
  }

  onClosedResponsiveMenu() {
    this.responsiveBarsIcon = true;
  }

  onOpenResponsiveMenu() {
    if (this.responsiveBarsIcon) {
      this.responsiveBarsIcon = false;
    }
  }

  onClosedUserMenu() {

    this.opacityUserIcon = false;
  }

  onOpenUserMenu() {
    if (!this.opacityUserIcon) {

      this.opacityUserIcon = true;

    }

  }
  onClickGoToAccount() {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    if (this.activatedRoute.snapshot.url[1]) {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'product',
        eventAction: 'navigation',
        eventLabel: 'go to account',
        eventValue: '',
        pageName: 'renewal area:products and services.product',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: 'product',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: 'renewal area:products and services',
        referer: 'https://renewals.pandasecurity.com/',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services.product');

    } else {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'products and services',
        eventAction: 'navigation',
        eventLabel: 'go to account',
        eventValue: '',
        pageName: 'renewal area:products and services',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: '',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: 'https://renewals.pandasecurity.com/',
        previousPage: 'renewal area:login',
        referer: 'https://renewals.pandasecurity.com/login',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services');
    }

    const configuration = localStorage.getItem('configuration');
    let conf = JSON.parse(configuration);
    let conf2 = conf.Urls.MyAccountApiUrl.split('/api')[0];
    window.open(conf2, "_blank");

  }

  onClickLogout() {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    if (this.activatedRoute.snapshot.url[1]) {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'product',
        eventAction: 'navigation',
        eventLabel: 'log out',
        eventValue: '',
        pageName: 'renewal area:products and services.product',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: 'product',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: 'renewal area:products and services',
        referer: 'https://renewals.pandasecurity.com/',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services.product');

    } else {
      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'products and services',
        eventAction: 'navigation',
        eventLabel: 'log out',
        eventValue: '',
        pageName: 'renewal area:products and services',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: '',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: 'https://renewals.pandasecurity.com/',
        previousPage: 'renewal area:login',
        referer: 'https://renewals.pandasecurity.com/login',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services');
    }
    localStorage.removeItem('token');
    localStorage.removeItem('products');
    localStorage.removeItem('coupon');
    localStorage.removeItem('xtrack');
    localStorage.removeItem('recommended');
    localStorage.removeItem('customerEmail');
    localStorage.removeItem('maintenanceId');
    localStorage.removeItem('products');
    localStorage.removeItem('productsVersion');
    localStorage.removeItem('customerId');
    localStorage.removeItem('url');

    this.router.navigateByUrl('/login');
  }
  onClickLogo() {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    if (this.activatedRoute.snapshot.url[1]) {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'product',
        eventAction: 'logo',
        eventLabel: 'top',
        eventValue: '',
        pageName: 'renewal area:products and services.product',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: 'product',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: 'renewal area:products and services',
        referer: 'https://renewals.pandasecurity.com/',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services.product');

    } else {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'products and services',
        eventAction: 'logo',
        eventLabel: 'top',
        eventValue: '',
        pageName: 'renewal area:products and services',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: '',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: 'https://renewals.pandasecurity.com/',
        previousPage: 'renewal area:login',
        referer: 'https://renewals.pandasecurity.com/login',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services');
    }
  }

  onClickUserAccount() {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    if (this.activatedRoute.snapshot.url[1]) {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'product',
        eventAction: 'navigation',
        eventLabel: 'account',
        eventValue: '',
        pageName: 'renewal area:products and services.product',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: 'product',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: 'renewal area:products and services',
        referer: 'https://renewals.pandasecurity.com/',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services.product');

    } else {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'products and services',
        eventAction: 'navigation',
        eventLabel: 'account',
        eventValue: '',
        pageName: 'renewal area:products and services',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: '',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: 'https://renewals.pandasecurity.com/',
        previousPage: 'renewal area:login',
        referer: 'https://renewals.pandasecurity.com/login',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services');
    }
  }

  onClickNavBarProducts() {
    
    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    if (this.activatedRoute.snapshot.url[1]) {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'product',
        eventAction: 'navigation',
        eventLabel: 'products and services',
        eventValue: '',
        pageName: 'renewal area:products and services.product',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: 'product',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: 'renewal area:products and services',
        referer: 'https://renewals.pandasecurity.com/',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services.product');
    }
    else {

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'products and services',
        eventAction: 'navigation',
        eventLabel: 'products and services',
        eventValue: '',
        pageName: 'renewal area:products and services',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: '',
        pageSubcategory2: '',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: 'renewal area:products and services',
        referer: 'https://renewals.pandasecurity.com/login',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId: localStorage.getItem('customerId'),
        clientIdList: sessionStorage.getItem('clientIdsList'),
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: '',
        productId: '',
        productSKU: '',
        productState: '',
        productDevices: '',
        productMonths: '',
        productSubscriptionType: '',
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: '',
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services');

    }

  }

  ngOnDestroy() {

  

  }
}
